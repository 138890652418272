<template>
  <div>
    <ConcurrentCase></ConcurrentCase>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
const ConcurrentCase = defineAsyncComponent(() =>
  import("@/components/cases/ConcurrentCase")
);
export default {
  components: {
    ConcurrentCase,
  },
};
</script>
